<template>
  <vz-autocomplete
    ref="autocomplete"
    :value-key="valueKey"
    value-template="{name}"
    title-template="{name}"
    placeholder="Введите текст для поиска"
    :modelValue="modelValue"
    :items="items"
    :use-spinner="useSpinner"
    custom-description-template
    @search="search"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import VzAutocomplete from '~/components/ui-kit/vz-autocomplete.vue'
import {Contractor} from '~/types/types'
import {ContractListPostData} from '~/types/api';
import {PropType} from 'vue';
import {getContractor, getContractors as getContractorsApi} from '@/api/contractor';
import {deepCopyObject} from '~/common/functions';
import {useValidateble} from '~/composables/useValidateble';

// Условно наследуем компонент от VzAutocomplete
defineOptions({
  inheritAttrs: false
})

const emit = defineEmits(['has-same'])

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  valueKey: {
    type: String as PropType<'guid'|'name'|'inn'>,
    default: 'guid'
  },
  searchParams: {
    type: Object as PropType<ContractListPostData>,
    default: () => ({
      useContact: false,
      daData: false,
      count: false,
      limit: 10
    })
  }
})

useValidateble()

const items = ref([] as Contractor[])
const selected = ref<Contractor>()
const useSpinner = ref(false)
const autocomplete = ref()
let controller = new AbortController()

async function search(search: string|undefined = undefined) {
  useSpinner.value = true
  const contractors = (await getContractors(search))?.data
  useSpinner.value = false

  items.value = contractors?.map(prepareCustomer) || []
}

function getContractors(search: string|undefined) {
  controller.abort()
  controller = new AbortController()
  return getContractorsApi( { search, ...props.searchParams }, controller.signal).catch(err => {
    if (err.name == 'AbortError') {
      console.log("Прервано!");
    } else {
      throw err;
    }
  })
}

function prepareCustomer(contractor: Contractor) {
  return {
    ...contractor,
    descriptionTemplate: contractor?.inn ? 'ИНН:{inn} КПП:{kpp}' : 'Телефон: {defaultContact.phone}',
  }
}

async function checkError(silent: boolean = false): Promise<boolean> {
  return await autocomplete.value.checkError(silent)
}

function onChange(name: string, obj: Contractor) {
  selected.value = obj
  if (obj.name) {
    const sameNameContractors = items.value.filter(contractor => contractor.name === obj.name)
    if (sameNameContractors.length > 1) {
      emit('has-same', obj.name)
    }
  }
}

onMounted(async () => {
  if (!props.modelValue && !items.value.length) {
    await search()
    return
  }
  if (props.valueKey === 'name' || props.valueKey === 'inn') {
    await search(props.modelValue)
    return
  }
  if (props.valueKey === 'guid') {
    let selectedContractor = prepareCustomer(await getContractor(props.modelValue, undefined, props.searchParams.useContact))
    await search(selectedContractor?.name)
    if (selectedContractor) {
      selected.value = selectedContractor
      if (!items.value.find(item => item.guid === props.modelValue)) {
        console.log('!items.value.includes', props.modelValue, deepCopyObject(items.value))
        items.value = [selectedContractor, ...(items.value || [])]
      }
    }
  }
})

defineExpose({
  selected,
  checkError,
  search
})
</script>

<style scoped>

</style>